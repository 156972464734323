<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{  'فديوهات ' +subject }}
          </h2>
          <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
          <button class="btn btn-sm btn-success" @click="addVideo">
            <i class="ti-plus mL-5" style="color: #fff"></i>إضافة فديو
          </button>
          <button class="btn btn-sm btn-primary mR-10 mL-10" @click="print">
            <i class="ti-printer mL-5" style="color: #fff"></i>طباعة الفديوهات
          </button>
          <div id="dataTable_wrapper" class="dataTables_wrapper">
            <form @submit="checkForm">
              <div class="form-group row pB-30 pT-30">
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="videoId"
                    :placeholder="this.$t('videoId')"
                  />
                </div>
                <div class="col-sm-3">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model="videoName"
                    :placeholder="this.$t('videoName')"
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary mL-10"
                    :disabled="loading"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 100%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr role="row">
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    حذف
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    تعديل
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    عرض
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    رفع
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    تنزيل
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading">
                <tr role="row" class="odd" v-for="(item, index) in videos" :key="item.id">
                  <td>
                    {{ (videos.length - index) +' - '+ item.name }}
                  </td>
                  <td>
                    <a href="javascript:"
                    @click="deleteVideo(item)"
                      ><span class="badge rounded-pill bgc-red-50 c-red-700"
                        ><i class="ti-trash"></i></span
                    ></a>
                  </td>
                  <td>
                    <a href="javascript:"
                    @click="editVideo(item)"
                      ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                        ><i class="ti-pencil"></i></span
                    ></a>
                  </td>
                  <td>
                    <a :href="'https://yalla-emtihan.com/yalla-emtihan/public/videos/' + item.url" target="_blank"
                      ><span class="badge rounded-pill bgc-blue-50 c-blue-700"
                        ><i class="ti-eye"></i></span
                    ></a>
                  </td>
                  <td v-if="index != 0" @click="showPopup(videos.length - index,true,item.name)"><a href="javascript:"><i class="ti-angle-double-up"></i></a></td>
                  <td v-else><i class="ti-angle-double-up"></i></td>
                  <td v-if="index+1 != videos.length" @click="showPopup(videos.length - index,false,item.name)"><a href="javascript:"><i class="ti-angle-double-down"></i></a></td>
                  <td v-else><i class="ti-angle-double-down"></i></td>
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!videos.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
            <div style="text-align:center;margin-top:40px">
            <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="500" @paginate="getVideos"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="direction: rtl;">
            <h5 class="modal-title text-right" id="exampleModalLongTitle">تغيير خانة الفديو</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
              <span aria-hidden="true">&times;</span>
            </button>
      </div>
      <div class="modal-body">
       <div class="row">
        <div class="col-sm-12">{{ this.name +': من '+currentIndex+' إلى'}} <select v-model="selectedIndex">
        <option v-for="item in ids" :value="item">
          {{ item }}
        </option>
      </select></div>
       </div>
      </div>
      <div v-if="!popupLoading" class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">إلغاء</button>
        <button type="button" class="btn btn-primary" @click="updateVideoIndex">تغيير</button>
      </div>
      <div v-else class="modal-footer">
        <loading/>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="deletePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="direction: rtl;">
            <h5 class="modal-title text-right" id="exampleModalLongTitle">حذف فديو</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
              <span aria-hidden="true">&times;</span>
            </button>
      </div>
      <div class="modal-body">
       هل انت متأكد من انك تريد حذف الفديو{{ '  "'+name+'" ؟' }}
      </div>
      <div v-if="!popupLoading" class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">إلغاء</button>
        <button type="button" class="btn btn-danger" @click="deleteVideoApi">حذف</button>
      </div>
      <div v-else class="modal-footer">
        <loading/>
      </div>
    </div>
  </div>
</div>
<div id="pdf" hidden><videosPrintModel :videos="this.videos" :subject="this.subject"></videosPrintModel></div>
    </div>
  </template>
  
  <script>
  export default {
    props:{
      id: {
        type: String,
        required: true
      },
      subject: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loading: true,
        statusLoading: true,
        videoName: "",
        videoId: "",
        videos: [],
        pagination: {},
        page:1,
        ids: [],
        name: '',
        currentIndex: -1,
        selectedIndex:0,
        popupLoading: false,
        isUp:false,
        deleteVideoId:-1
      };
    },
    created() {
      this.getVideos();
    },
    methods: {
      print() {
  // Create an iframe
  var iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.style.border = 'none';
  document.body.appendChild(iframe);

  // Write the content to the iframe
  var doc = iframe.contentWindow.document;
  doc.open();
  doc.write("<html>");
  doc.write('<head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width,initial-scale=1.0"><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Cairo&display=swap" rel="stylesheet"/><link href="https://code.jquery.com/ui/1.10.2/themes/smoothness/jquery-ui.css" rel="stylesheet"/><link href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" rel="stylesheet"/><style>*:not(i) , h1 , h2 , h3 , h4 , h5 , h6 {font-family: "Cairo", sans-serif !important;}.rtl {direction: rtl;}table, th, td {border: 1px solid black;text-align:center}</style></head>');
  doc.write("<body>");
  var divContents = document.getElementById("pdf").innerHTML;
  doc.write(divContents);
  doc.write("</body></html>");
  doc.close();

  // Print the iframe content
  iframe.contentWindow.focus();
  iframe.contentWindow.print();

  // Clean up by removing the iframe
  document.body.removeChild(iframe);
}
,
      revcreateList(start, end) {
    let list = [];
    for (let i = end; i >= start; i--) {
        list.push(i);
    }
    return list;
},
      createList(start, end) {
    let list = [];
    for (let i = start; i <= end; i++) {
        list.push(i);
    }
    return list;
},
deleteVideo(video) {
this.deleteVideoId = video.id;
this.name = video.name;
$('#deletePopup').modal('show');
},
    showPopup(index,isUp,name) {
      this.isUp = isUp;
      this.currentIndex = index;
      this.name = name;
      if(!isUp) {
        this.ids = this.revcreateList( 1  , index - 1);
        this.selectedIndex = index - 1;
      }
      else {
        this.ids = this.createList( index+1 , this.videos.length );
        this.selectedIndex = index +1;
      }
      $('#popup').modal('show');
    },
      checkForm: function (e) {
        e.preventDefault();
        this.getVideos();
      },
      updateVideoIndex() {
        this.popupLoading = true;
        let ids = [this.videos[this.videos.length - this.currentIndex].id , this.videos[this.videos.length - this.selectedIndex].id];

        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .post(
            "https://yalla-emtihan.com/backend/videos/updateVideoIndex",
            {
              video_ids: ids,
              is_up: this.isUp,
              subject_id: this.id
            },
            { headers }
          )
          .then(
            function (data) {
              this.popupLoading = false;
              switch (data.status) {
                case 200:
                  this.success(this.$t("success"));
                  $('#popup').modal('hide');
                  this.getVideos();
                  break;
              } 
            },
            (err) => {
              this.popupLoading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            }
          );
      },
      deleteVideoApi() {
        this.popupLoading = true;

        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .post(
            "https://yalla-emtihan.com/backend/videos/deleteVideo",
            {
              video_id: this.deleteVideoId
            },
            { headers }
          )
          .then(
            function (data) {
              this.popupLoading = false;
              switch (data.status) {
                case 200:
                  this.success(this.$t("success"));
                  $('#deletePopup').modal('hide');
                  this.getVideos();
                  break;
              } 
            },
            (err) => {
              this.popupLoading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            }
          );
      },
      getVideos: function () {
        this.loading = true;
        this.$http
          .get("https://yalla-emtihan.com/backend/videos/getVideos", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              video_id: this.videoId,
              video_name: this.videoName,
              subject_id: this.id,
              page: this.page
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.videos = data.body.data;
                  this.pagination = data.body;
                  console.log(this.pagination);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      addVideo: function () {
        const panel1Handle = this.$showPanel({
          component: () => import("../../classes/subjects/AddVideo.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            videoID: this.id,
            reload:this.getVideos
          },
        });
      },
      editVideo(video) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../classes/subjects/EditVideo.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            videoObject: video,
            reload:this.getVideos
          },
        });
  
        panel1Handle.promise.then((result) => {});
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>